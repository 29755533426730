var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-button",
        {
          staticClass: "editRow rounded-lg px-3 py-2 mr-2",
          attrs: { type: "border" },
          on: { click: _vm.openCoordinates }
        },
        [_vm._v(" " + _vm._s(this.properties.itemTitle) + " ")]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "modalMap",
          attrs: { active: _vm.isModalOpen, title: "" },
          on: {
            "update:active": function($event) {
              _vm.isModalOpen = $event
            }
          },
          model: {
            value: _vm.isModalOpen,
            callback: function($$v) {
              _vm.isModalOpen = $$v
            },
            expression: "isModalOpen"
          }
        },
        [
          _c("h3", [_vm._v("Map")]),
          _c("p", { staticClass: "text-sm text-dark" }, [
            _vm._v(_vm._s(_vm.mapItemName))
          ]),
          _c(
            "div",
            [
              _c("MapCoordinates", {
                attrs: {
                  defaultCoordinates: _vm.defaultCoordinates,
                  componentSize: _vm.mapSize,
                  disabled: true,
                  zoom: 18
                }
              }),
              _c(
                "vs-button",
                {
                  staticClass: "mr-2 px-5 rounded-lg mt-5 float-right",
                  attrs: { type: "border" },
                  on: { click: _vm.saveMap }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("resorts.touristic-objects.mapEditButton")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }