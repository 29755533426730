<template>
    <div>
        <div class="rounded-lg advice-container w-full md:w-3/3 lg:w-2/2 flex justify-center items-center">
            <div class="px-3 text-xs md:text-lg sm:w-1/1 md:w-3/3 lg:w-2/2 flex justify-center items-center">
                <feather-icon icon="InfoIcon" svgClasses="text-primary w-5" class="align-middle mt-0 mr-3"/>
                <p class="mt-4  text-primary">{{ $t("resorts.touristic-objects.advice") }}</p>
            </div>
        </div>
        <DatatablePageLayout>
            <template v-slot:actionButtons>
                <vs-button
                    class="rounded-lg xs:w-full sm:w-auto"
                    @click="goToCreate">
                    {{ addNewItemTranslation }}
                </vs-button>
            </template>
            <template v-slot:datatable>
                <Datatable
                    :config="datatableConfig"
                    :elements="touristicObjects"
                    :stripe="true"
                    :sst="true"
                    :pagination="pagination"
                    :customActions = "customActions"
                    pagination-position="top"
                    :show-refresh-button="true"
                    @refresh="handleRefresh"
                    @filter="handleFilter"
                    @action="handleAction"
                    @checkbox="handleCheckbox"
                    :delete-dialog="{
                        title: $t('resorts.touristic-objects.tableSections.deleteDialogTitle'),
                        text: $t('resorts.touristic-objects.tableSections.deleteDialogText'),
                    }"
                />
            </template>

        </DatatablePageLayout>
    </div>
</template>

<script>

import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'
import TouristicObjectServices from '@/modules/Resorts/Services/TouristicObjectServices'
import showMapButton from '@/modules/Resorts/Pages/touristicObjects/components/datatable/showMapButton.vue';
import {DateTime} from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import loader from "@/modules/Shared/Mixins/loader";
import i18n from "@/i18n";
import DatatablePageLayout from "@/modules/Shared/Pages/DatatablePageLayout"

export default {
    name: 'touristicObjects',
    mixins: [loader],
    components: {
        DatatablePageLayout,
        Datatable
    },

    data() {
        return {
            resortUuid: '',
            newUuid: uuidv4(),
            touristicObjects: [],
            baseFilters: [],
            currentFilters: [],
            currentPage: 1,
            pagination: {},
            datatableConfig: [
                { 'field': 'image', head: this.$t('resorts.touristic-objects.tableSections.mainImage'), "type": 'image', 'searchable': false, 'sortable': false },
                { 'field': 'name', head: this.$t('resorts.touristic-objects.tableSections.name'), 'searchable': true, 'sortable': false },
                { 'field': 'updatedAt', head: this.$t('resorts.touristic-objects.tableSections.updatedAt'), 'sortable': false },
                { 'field': 'provider', head: this.$t('resorts.touristic-objects.tableSections.provider'), 'sortable': false },
                { 'field': 'published', 'type': 'check', head: this.$t('resorts.touristic-objects.tableSections.published'), 'sortable': true },
                { 'field': 'selection',"type": 'html', head: this.$t('resorts.touristic-objects.tableSections.selection'), 'searchable': true, 'sortable': false },
                { 'field': 'actions', "type": 'actions', head: this.$t('resorts.touristic-objects.tableSections.actions'),  'sortable': false },
                { 'field': 'map', "type": 'actions',  head: this.$t('resorts.touristic-objects.tableSections.map'),  'sortable': false },
            ],
            customActions:[
                {
                    actionName: 'showMap',
                    component: showMapButton,
                    props: {
                        itemTitle: this.$t('resorts.touristic-objects.tableSections.mapButton'),
                        resort_uuid: this.$route.params.uuid ,
                        pageType: this.$route.meta.type,
                    }
                },

            ]
        };
    },
    async created() {
        this.resortUuid = this.$route.params.uuid
        this.objectPerPage = 25
        this.mapSize = {width:"w-full md:w-3/3 lg:w-2/2"}

        this.baseFilters = [
            {
                field: 'resort_uuid',
                value: this.resortUuid
            },
            {
                field: 'category',
                value: this.getCategory()
            }
        ]
        this.currentFilters = this.baseFilters

        if(this.$route.meta.type == 'events'){
            this.datatableConfig.splice(3, 0, { 'field': 'start_date', head: this.$t('resorts.touristic-objects.tableSections.startDate'), 'sortable': false })
            this.datatableConfig.splice(4, 0, { 'field': 'end_date', head: this.$t('resorts.touristic-objects.tableSections.endDate'), 'sortable': false } )
        }

        this.getTouristicObjects({})
    },
    computed: {
        addNewItemTranslation() {
            switch (this.$route.meta.type) {
                case 'events':
                    return i18n.t("resorts.touristic-objects.addEvent")
                case 'services':
                    return i18n.t("resorts.touristic-objects.addService")
                case 'facilities':
                    return i18n.t("resorts.touristic-objects.addFacility")
            }
        }
    },
    methods:{
        goToCreate() {
            this.$router.push({
                name: `edit-touristicObject-${this.$route.meta.type}`,
                params: {
                    'uuid': this.resortUuid,
                    'touristicObjectUuid': this.newUuid,
                    'type': this.$route.meta.type ,
                    'create': true
                }
            })
        },
        getCategory() {
            switch (this.$route.meta.type) {
                case 'events':
                    return 'event'
                case 'services':
                    return 'service'
                case 'facilities':
                    return 'facility'
            }
        },
        async getTouristicObjects() {
            await this.load(async () => {
            const touristicObjectData = await TouristicObjectServices.getTouristicObjects(this.currentFilters, this.currentPage,  this.objectPerPage)

            this.pagination = {
                per_page: touristicObjectData.per_page,
                total: touristicObjectData.total
            }
            this.touristicObjects = touristicObjectData.data.map(this.touristiObjectToTableRow)
        })
        },
        touristiObjectToTableRow(touristicObject){
            let url = touristicObject.image_list.length > 0 ? touristicObject.image_list[0].url : ""

            return {
                uuid:touristicObject.uuid,
                image: url,
                name:touristicObject.name,
                selection:this.joinSelections(touristicObject.selections),
                provider:touristicObject.touristic_object_data_provider.name,
                ...(touristicObject.touristic_object_data_provider.id == 7 ? {'actions' : ['view','delete','edit']} : {'actions' : ['view']})  , // If provider == RMP delete&edit
                start_date: this.toCorrectDateFormatFromDate(touristicObject.start_date),
                end_date: this.toCorrectDateFormatFromDate(touristicObject.end_date),
                published: touristicObject.published,
                map: ['showMap'],
                coordinates: touristicObject.coordinates,
                updatedAt: this.toCorrectDateFormatFromTimestamp(touristicObject.updated_at)
            }
        },

        touristicObjectPublished(touristicObject){
            switch (touristicObject.published) {
                case true: return `${this.$t('resorts.touristic-objects.tableSections.publish_published')}`
                case false: return `${this.$t('resorts.touristic-objects.tableSections.publish_not_published')}`
            }
        },
        toCorrectDateFormatFromDate(date){
            // Format dd/mm/YYYY
            if (date === null) {
                return ""
            }
            return new Date(date).toLocaleDateString()
        },
        toCorrectDateFormatFromTimestamp(timestamp){
            return DateTime.fromSeconds(timestamp).toFormat('dd/MM/yyyy HH:mm')
        },
        async handleRefresh() {
          await this.getTouristicObjects()
        },

        async handleFilter({page, filters}) {
            this.currentFilters = [...this.baseFilters, ...filters]
            this.currentPage = page;
            await this.getTouristicObjects()
        },
        async handleAction(eventType, uuid, __element) {

            switch (eventType) {
                case 'view': {
                    const path = `/resorts/${this.resortUuid}/${this.$route.meta.type}/${__element.uuid}/view`
                    if (this.$route.path !== path) await this.$router.push(path)
                    break
                }
                case 'edit': {
                    const path = `/resorts/${this.resortUuid}/${this.$route.meta.type}/${__element.uuid}/edit`
                    if (this.$route.path !== path) await this.$router.push(path)
                    break
                }
                case 'delete': {
                    await this.load(async () => {
                    await TouristicObjectServices.deleteTouristicObject(uuid)
                    this.touristicObjects = this.touristicObjects.filter(touristicObject => touristicObject.uuid !== uuid)
                })
                    break
                }
            }
        },
        async handleCheckbox(touristicObjectUuid, value, field) {
            await TouristicObjectServices.updateTouristicObject( touristicObjectUuid, {
                [field]: value,
            })
        },
        async saveMap(){
            const path = `/resorts/${this.resortUuid}/touristicobjects/${this.mapUuid}/edit/${this.$route.meta.type}`
            if (this.$route.path !== path) await this.$router.push(path)
        },

        joinSelections(selections){
            let joinedSelections = []
            selections.forEach(selection => {
                joinedSelections.push(` ${selection.selection}` )
            });

            return `<div class="w-72 pt-2"><p class="break-words">${joinedSelections.toString()}</p></div>`

        }
    }

}
</script>
