<template>
    <div >
        
        <vs-button  type="border" class="editRow rounded-lg px-3 py-2 mr-2" @click="openCoordinates">
            {{ this.properties.itemTitle }}
        </vs-button>   

        <vs-popup  class="modalMap" v-model="isModalOpen" :active.sync="isModalOpen" title="">    
            <h3>Map</h3>        
            <p class="text-sm text-dark">{{ mapItemName }}</p>  
            <div >
                <MapCoordinates 
                :defaultCoordinates=defaultCoordinates
                :componentSize = mapSize
                :disabled="true"
                :zoom=18
                ></MapCoordinates>   
                <vs-button  type="border" class="mr-2 px-5 rounded-lg mt-5 float-right" @click="saveMap">
                    {{ $t("resorts.touristic-objects.mapEditButton") }}
                </vs-button>    
            </div>      
        </vs-popup>

    </div>
</template>

<script>
import MapCoordinates from '@/modules/Shared/Components/mapcoordinates/MapCoordinates.vue'

export default {
    name:'show-map-button',
    components: {
        MapCoordinates,
    },
    props: {
        properties:{type: Object,required: true}
    },
    data(){
        return {
            isModalOpen: false,
        }
    },
    created(){
        this.defaultCoordinates = {lat:0,lon:0}
        this.mapUuid = ""      
        this.mapItemName = "" 
        this.mapSize = {width:"w-full"}
    },
    methods:{ 
        openCoordinates(){
            this.defaultCoordinates = {
                lat:this.properties.rowInfo.coordinates.lat,
                lon:this.properties.rowInfo.coordinates.lon
            }                
            this.mapUuid = this.properties.rowInfo.uuid
            this.mapItemName = this.properties.rowInfo.name
            this.isModalOpen = true 
        },
        async saveMap(){  
            const path = `/resorts/${this.properties.resort_uuid}/${this.properties.pageType}/${this.mapUuid}/edit`                 
            if (this.$route.path !== path) await this.$router.push(path)  
        }
    
    }
    
}
</script>