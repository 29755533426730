var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "rounded-lg advice-container w-full md:w-3/3 lg:w-2/2 flex justify-center items-center"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "px-3 text-xs md:text-lg sm:w-1/1 md:w-3/3 lg:w-2/2 flex justify-center items-center"
            },
            [
              _c("feather-icon", {
                staticClass: "align-middle mt-0 mr-3",
                attrs: { icon: "InfoIcon", svgClasses: "text-primary w-5" }
              }),
              _c("p", { staticClass: "mt-4  text-primary" }, [
                _vm._v(_vm._s(_vm.$t("resorts.touristic-objects.advice")))
              ])
            ],
            1
          )
        ]
      ),
      _c("DatatablePageLayout", {
        scopedSlots: _vm._u([
          {
            key: "actionButtons",
            fn: function() {
              return [
                _c(
                  "vs-button",
                  {
                    staticClass: "rounded-lg xs:w-full sm:w-auto",
                    on: { click: _vm.goToCreate }
                  },
                  [_vm._v(" " + _vm._s(_vm.addNewItemTranslation) + " ")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "datatable",
            fn: function() {
              return [
                _c("Datatable", {
                  attrs: {
                    config: _vm.datatableConfig,
                    elements: _vm.touristicObjects,
                    stripe: true,
                    sst: true,
                    pagination: _vm.pagination,
                    customActions: _vm.customActions,
                    "pagination-position": "top",
                    "show-refresh-button": true,
                    "delete-dialog": {
                      title: _vm.$t(
                        "resorts.touristic-objects.tableSections.deleteDialogTitle"
                      ),
                      text: _vm.$t(
                        "resorts.touristic-objects.tableSections.deleteDialogText"
                      )
                    }
                  },
                  on: {
                    refresh: _vm.handleRefresh,
                    filter: _vm.handleFilter,
                    action: _vm.handleAction,
                    checkbox: _vm.handleCheckbox
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }